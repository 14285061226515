<template>
  <Page id="treatments">
    <section
      class="section-indent section-top page-top"
      aria-labelledby="aria-treatments"
    >
      <h1 id="aria-treatments">Our Treatments &amp; Fees</h1>
      <p>
        Happy dental offers appointments to private care patients. We also offer
        pricing plans for some of our larger-priced services. For more
        information please get in touch.
      </p>
    </section>
    <FeeTable v-for="f in fees" :key="f.name" :model="f" />
  </Page>
</template>

<script>
import FeeTable from "@/components/FeeTable.vue";
import Page from "@/components/core/Page.vue";
import Fees from "@/data/fees.json";

export default {
  components: { FeeTable, Page },
  data() {
    return {
      fees: Fees,
    };
  },
};
</script>

<style scoped></style>
