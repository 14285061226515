<template>
  <section class="section-indent" :class="mq">
    <div class="feetable">
      <div class="feetable-top feetable-row">
        <p>{{ model.name }}</p>
        <p class="true-center">Price</p>
      </div>
      <div class="feetable-bottom">
        <div v-for="m in model.fees" :key="m.name" class="feetable-row">
          <p>{{ m.name }}</p>
          <p>{{ m.price }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  inject: ["mq"],
  props: {
    model: Object,
  },
};
</script>

<style scoped>
.feetable {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding-bottom: 10px;
  margin-top: -80px;
  overflow: hidden;
}

.feetable,
.feetable-bottom {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.feetable-row {
  grid-template-columns: 1fr 200px;
  height: var(--size-textfield);
  align-items: center;
  display: grid;
}

.brMobile .feetable-row {
  grid-template-columns: 1fr 120px;
}

.feetable-row > p:first-child {
  margin-left: 30px;
}

.feetable-row > p:last-child {
  justify-content: center;
  display: flex;
}

.brMobile .feetable-row > p:last-child {
  font-size: var(--font-small);
}

.feetable-top {
  background: var(--primary);
  color: white;
  gap: 20px;
}

.feetable-top > p:last-child {
  background: var(--accent);
  height: 100%;
}
</style>
